import { useTabs } from '@/composables/useTabs.ts'
import { computed, Ref, ref } from 'vue'
import type { Bill, BillWithPayments, PaymentMethod } from '@/types'
import Bills from '@last/core/src/billsGenerator'
import { CalculatorPayload } from '@/components/Calculator/CalculatorUtils.ts'
import { useConfigStore } from '@/store/config.ts'
import { v4 as uuid } from 'uuid'
import { useNotifications } from '@/composables/useNotifications.ts'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { useTabsStore } from '@/store/tabs'
import TicketPrinter from '@/ticketPrinter'
import { useRoute, useRouter } from 'vue-router'
import Printer from '@/integrations/printer/printer'
import { iZettlePlugin } from '@/integrations/iZettlePlugin'
import { SumUpPlugin } from '@/integrations/SumUpPlugin'
import Dataphone from '@/integrations/dataphone/dataphone'
import { currency } from '@last/core-ui/.storybook/filters.ts'
import { dialog } from '@last/core-ui/paprika/plugins/dialog/dialog'
import { storeToRefs } from 'pinia'
import { useBillingStore } from '@/store/billing'
import { usePromotionsStore } from '@/store/promotions'
import i18n from '@/i18n'

export const useCheckout = (
  tabId: string,
  checkoutType:
    | 'normalCheckout'
    | 'fastCheckout'
    | 'fastPOS' = 'normalCheckout',
  calculatorPayload: Ref<CalculatorPayload> = ref({
    pending: 0,
    change: 0,
    toPay: 0,
    tip: 0
  })
) => {
  const billingStore = useBillingStore()
  const { deleteTabPromotion, getTabGlobalPromotion } = usePromotionsStore()
  const { tab, allProducts, allBills, pendingBill, globalDiscount } =
    useTabs(tabId)
  const { addBill, addPayment, closeTab } = useTabsStore()
  const { getCurrentBill } = storeToRefs(billingStore)
  const { config } = useConfigStore()
  const { notifyTabClosed, notifyPaymentFailed } = useNotifications()
  const router = useRouter()
  const route = useRoute()
  const { t } = i18n.global

  const selectedBillId = ref<string>('')
  const selectedProductsIds = ref<{ id: string; uniqueId: string }[]>([])
  const isCharging = ref<boolean>(false)

  const currentDiscount = computed(() => {
    return globalDiscount.value
  })

  const currentBill = computed<Bill>(() => {
    if (['fastCheckout', 'fastPOS'].includes(checkoutType)) {
      const pendingBills = allBills.value.filter(
        bill => bill.pending && bill.pending > 0
      )
      if (pendingBills.length > 0) {
        selectedBillId.value = pendingBills[0].id
        return pendingBills[0]
      } else {
        const bill = pendingBill.value
        if (!bill) return
        const extendedBill = Bills.addPaymentInfo(bill, [])
        selectedBillId.value = extendedBill.id

        return extendedBill
      }
    }

    if (checkoutComplete.value) return null
    const current = getCurrentBill.value({
      tabId: tabId,
      currentDiscount: currentDiscount.value,
      selectedProductIds: selectedProductsIds.value
    })

    const extendedBill = Bills.addPaymentInfo(current, [])
    selectedBillId.value = extendedBill.id

    return extendedBill
  })

  const selectedBill = computed<BillWithPayments | Bill>(() => {
    if (['fastPOS'].includes(checkoutType)) {
      return currentBill.value
    }

    return bills.value.find(bill => bill.id === selectedBillId.value)!
  })

  const bills = computed<BillWithPayments[]>(() => {
    const existingBills = allBills.value
    if (checkoutComplete.value) {
      return existingBills
    } else {
      const currentBillWithPaymentInfo = Bills.addPaymentInfo(
        currentBill.value,
        []
      )

      return [currentBillWithPaymentInfo, ...existingBills]
    }
  })

  const pendingBills = computed<BillWithPayments[]>(() => {
    return bills.value.filter(bill => bill.pending && bill.pending > 0) || []
  })

  const paymentMethods = computed<PaymentMethod[]>(() =>
    config.paymentMethodsConfig.map(method => ({
      ...method,
      type: method.name.toLowerCase()
    }))
  )

  const cashPaymentMethod = computed(() => {
    return paymentMethods.value.find(method => method.type === 'cash')
  })

  const paymentMethod = ref<PaymentMethod>(
    cashPaymentMethod.value ?? paymentMethods.value[0]
  )

  const toCharge = computed(() => {
    if (paymentMethod.value.type === 'cash' && currentBill.value?.pending) {
      return Math.min(currentBill.value.pending, calculatorPayload.value.toPay)
    } else {
      return calculatorPayload.value.toPay
    }
  })

  const canCharge = computed(() => {
    if (!selectedBill.value) return false
    if (['fastCheckout', 'fastPOS'].includes(checkoutType)) {
      return toCharge.value >= currentBill.value.pending! && !isCharging.value
    }

    return toCharge.value > 0 || selectedBill.value.total === 0
  })

  function selectPaymentMethod(method: string): void {
    const payment = paymentMethods.value.find(m => m.type === method)
    if (payment) {
      paymentMethod.value = payment
    }
  }

  async function charge(avoidConfirm: boolean): Promise<void> {
    if (!canCharge.value) return
    isCharging.value = true

    const paymentId = uuid()

    if (CashMachine.methods.includes(paymentMethod.value.type)) {
      await chargeWithMethod()
    } else if (paymentMethod.value.type === 'cash') {
      executePayment(paymentId)
      if (calculatorPayload.value.change > 0 && !avoidConfirm) {
        showChangeConfirmationDialog()
      }
    } else {
      if (calculatorPayload.value.tip > 0 && !avoidConfirm) {
        showConfirmTipDialog()
      } else {
        await chargeWithMethod()
      }
    }

    if (paymentMethod.value.openDrawer) {
      Printer.openCashDrawer()
    }

    isCharging.value = false
  }

  async function chargeWithMethod(): Promise<void> {
    try {
      const paymentId = uuid()
      let metadata = null
      if (paymentMethod.value.type === 'izettle') {
        await iZettlePlugin.charge({ amount: toCharge.value })
      } else if (paymentMethod.value.type === 'sumup') {
        await SumUpPlugin.charge({ amount: toCharge.value })
      } else if (CashMachine.methods.includes(paymentMethod.value.type)) {
        const charged = await CashMachine.charge(toCharge.value)
        if (charged === 0) throw new Error('CashMachine charging failed')
      } else if (paymentMethod.value.type === 'dataphone') {
        if (!Dataphone.initialized()) {
          throw new Error('Dataphone is not initialized')
        } else {
          metadata = await Dataphone.charge({
            amount: toCharge.value,
            paymentId,
            tabId: tabId
          })
        }
      }
      executePayment(paymentId, metadata)
    } catch (error) {
      notifyPaymentFailed((error as Error).message)
    }
  }

  function executePayment(paymentId?: string, metadata?: any): void {
    const bill = selectedBill.value
    const billId = bill.id
    const amount = toCharge.value
    addBill({ tabId, bill })
    addPayment({
      billId,
      paymentId,
      change:
        paymentMethod.value.type === 'cash'
          ? 0
          : calculatorPayload.value.change,
      tip: calculatorPayload.value.tip,
      amount,
      type: paymentMethod.value.type,
      metadata,
      tabId: tabId,
      tillId: undefined
    })
    const printBill = bills.value.find(bill => bill.id === billId)!
    const pending = printBill.pending ?? selectedBill.value.pending
    if (
      pending <= 0 &&
      (((tab.value.tables?.length || 0) === 0 &&
        config.organizationConfig.takeawayAutoprint) ||
        config.organizationConfig.restaurantAutoprint)
    ) {
      TicketPrinter.printBill(printBill)
    }

    onPaymentSuccess()
  }

  function onPaymentSuccess(): void {
    if (['fastCheckout', 'fastPOS'].includes(checkoutType)) {
      closeTab({ tabId, closedWithPin: false })

      if (checkoutType === 'fastCheckout' && route.name !== 'pos') {
        router.push({ name: 'pos' })
      }

      notifyTabClosed(tabId)
      isCharging.value = false

      return
    }

    selectedProductsIds.value = []
    if (selectedBill.value.pending === 0 && currentBill.value) {
      selectedBillId.value = currentBill.value.id
    } else if (
      selectedBill.value.pending === 0 &&
      pendingBills.value.length > 0
    ) {
      selectedBillId.value = pendingBills.value[0].id
    }
    isCharging.value = false
  }

  const checkoutComplete = computed(() => {
    const tabProducts = allProducts.value
    return (
      tabProducts
        .map(product => product.notBilledQuantity)
        .reduce((total, quantity) => total + quantity, 0) === 0
    )
  })

  const checkoutCompleteWithPayments = computed<boolean>(() => {
    const tabProducts = allProducts.value
    const allProductsBilled =
      tabProducts
        .map(product => product.notBilledQuantity)
        .reduce((total, quantity) => total + quantity, 0) === 0
    const pendingPayments = bills.value
      .map(bill => bill.pending)
      .reduce((sum, pending) => sum + pending, 0)
    return allProductsBilled && pendingPayments === 0
  })

  function showConfirmTipDialog(): void {
    dialog({
      title: `${t('checkout.confirm-tip')} ${currency(calculatorPayload.value.tip)}`,
      secondaryLabel: t('checkout.cancel'),
      onConfirm: () => {
        charge(true)
      },
      onSecondary: () => {
        isCharging.value = false
      },
      onCancel: () => {
        isCharging.value = false
      }
    })
  }

  function showChangeConfirmationDialog(): void {
    dialog({
      title: t('checkout.change'),
      content: `${t('checkout.change-info')} ${currency(calculatorPayload.value.change)}`
    })
  }

  async function removeDiscount(): Promise<void> {
    await deleteTabPromotion({
      tabId,
      tabPromotion: getTabGlobalPromotion(tabId)!
    })
  }

  return {
    bills,
    selectedBill,
    selectedBillId,
    currentBill,
    pendingBills,
    selectedProductsIds,
    checkoutComplete,
    checkoutCompleteWithPayments,
    paymentMethods,
    paymentMethod,
    isCharging,
    canCharge,
    charge,
    selectPaymentMethod,
    removeDiscount
  }
}
